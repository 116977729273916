/* Custom Styling */

/* import fonts */

@font-face {
    font-family: 'Isabi-Regular';
    src: url('./font/PTSerif-Regular.ttf');
}

@font-face {
    font-family: 'Isabi-Bold';
    src: url('./font/PTSerif-Bold.ttf');
}

@font-face {
    font-family: 'Isabi-BoldItalic';
    src: url('./font/PTSerif-BoldItalic.ttf');
}

@font-face {
    font-family: 'Isabi-Italic';
    src: url('./font/PTSerif-Italic.ttf');
}

@font-face {
    font-family: 'Isabi-Sans';
    src: url('./font/OpenSans-Regular.ttf');
}

@font-face {
    font-family: 'Isabi-Sans-Bold';
    src: url('./font/OpenSans-Bold.ttf');
}

@font-face {
    font-family: 'Isabi-Sans-ExtraBold';
    src: url('./font/OpenSans-ExtraBold.ttf');
}

@font-face {
    font-family: 'Isabi-Sans-SemiBold';
    src: url('./font/OpenSans-SemiBold.ttf');
}

@font-face {
    font-family: 'Isabi-Sans-Light';
    src: url('./font/OpenSans-Light.ttf');
}

/* Header */

.isabi-header {
    font-family: 'Isabi-Regular' !important;
    color: #ffffff !important;
    font-size: 80px !important;
}

.isabi-header_m {
    font-family: 'Isabi-Regular' !important;
    color: #ffffff !important;
    font-size: 45px !important;
}

.isabi-header-prize {
    font-family: 'Isabi-Regular' !important;
    color: #2F2C2A !important;
    font-size: 100px !important;
}

.isabi-header-prize_m {
    font-family: 'Isabi-Regular' !important;
    color: #2F2C2A !important;
    font-size: 50px !important;
}

.isabi-mini-header {
    font-family: 'Isabi-Sans-Semibold' !important;
    color: #8CA83D !important;
    letter-spacing: 3px !important;
    font-size: 18px !important;
}

.isabi-mini-header_m {
    font-family: 'Isabi-Sans-Semibold' !important;
    color: #8CA83D !important;
    letter-spacing: 3px !important;
    font-size: 14px !important;
}

.isabi-header-solid {
    border-top: 2px solid #ffffff !important;
    width: 40px !important;
}

.isabi-header-solid_m {
    border-top: 2px solid #ffffff !important;
    width: 30px !important;
}

/* Sections */

.barley-section {
    background-image: url('./img/barley_bg.jpg');
    background-color: #cccccc;
    /* min-height: 500px;  */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.isabi-section-solid {
    border-top: 2px solid #88C444 !important;
    width: 40px !important;
}

.isabi-section-solid_m {
    border-top: 2px solid #88C444 !important;
    width: 30px !important;
}

.isabi-section-header {
    font-family: 'Isabi-Bold' !important;
    color: #2F2C2A !important;
    font-size: 40px !important;
}

.isabi-section-header_m {
    font-family: 'Isabi-Bold' !important;
    color: #2F2C2A !important;
    font-size: 25px !important;
}

.isabi-section-header-white {
    font-family: 'Isabi-Bold' !important;
    color: #ffffff !important;
    font-size: 40px !important;
}

.isabi-section-header-white_m {
    font-family: 'Isabi-Bold' !important;
    color: #ffffff !important;
    font-size: 25px !important;
}

.isabi-address-header {
    font-family: 'Isabi-Bold' !important;
    color: #2F2C2A !important;
    font-size: 20px !important;
}

.isabi-address-header_m {
    font-family: 'Isabi-Bold' !important;
    color: #2F2C2A !important;
    font-size: 16px !important;
}

.isabi-section-text {
    font-family: 'Isabi-Sans' !important;
    color: #2F2C2A !important;
    /* font-size: 40px !important; */
}

.isabi-blog-text {
    font-family: 'Isabi-Sans-SemiBold' !important;
    color: #2F2C2A ;
    font-size: 14px !important;
    font-weight: 900;
}

.isabi-section-text_m {
    font-family: 'Isabi-Sans' !important;
    color: #2F2C2A !important;
    font-size: 14px !important;
}

.isabi-section-text-white {
    font-family: 'Isabi-Sans' !important;
    color: #ffffff !important;
    /* font-size: 40px !important; */
}

.isabi-section-text-white_m {
    font-family: 'Isabi-Sans' !important;
    color: #ffffff !important;
    font-size: 14px !important;
}

.isabi-number-header {
    font-family: 'Isabi-Bold' !important;
    color: #2F2C2A !important;
    font-size: 30px !important;
}

.isabi-number-header_m {
    font-family: 'Isabi-Bold' !important;
    color: #2F2C2A !important;
    font-size: 25px !important;
}

.isabi-email-link {
    color: #2F2C2A !important;
    text-decoration: none !important;
}

.num-1 {
    background-image: url('./img/num_1.svg');
    background-repeat: no-repeat;
}

.num-2 {
    background-image: url('./img/num_2.svg');
    background-repeat: no-repeat;
}

.num-3 {
    background-image: url('./img/num_3.svg');
    background-repeat: no-repeat;
}

.num-4 {
    background-image: url('./img/num_4.svg');
    background-repeat: no-repeat;
}

/* Nav */

.isabi-nav li a {
    text-transform: capitalize !important;
    font-family: 'Isabi-Sans-SemiBold' !important;
    color: #ffffff !important;
    font-size: 18px !important;
    padding-right: 30px
}

.isabi-nav li a:hover {
    color: #8CA83D !important;
}

/* Form */

.isabi-form {
    border-radius: 5px !important;
    border: 2px solid #2F2C2A !important;
    padding: 30px 10px !important;
    font-family: 'Isabi-Sans' !important;
}

.isabi-label {
    font-family: 'Isabi-Bold' !important;
    color: #2F2C2A !important;
    font-size: 18px !important;
}

.isabi-label_m {
    font-family: 'Isabi-Bold' !important;
    color: #2F2C2A !important;
    font-size: 15px !important;
}

.isabi-button-lg {
    background-color: #8CA83D !important;
    color: #ffffff !important;
    font-family: 'Isabi-Sans-SemiBold' !important;
    border-radius: 5px !important;
    text-transform: none !important;
}

.isabi-button-lg:hover {
    background-color: #2F2C2A !important;
    color: #88C444 !important;
}

/* Footer */

.footer-section {
    background-color: #F8F6EE !important;
}

.footer-text {
    font-family: 'Isabi-Sans-SemiBold' !important;
    color: #6F4A27 !important;
    font-size: 12px !important;
}

.toggle {
    color: #ffffff !important;
}